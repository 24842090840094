.RecruitManageContainer {
    width: 50%; 
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    &__ScoreBox {
        &__list {
            flex-shrink: 0;
            display: flex;
            text-align: center;
        }
        &__input {
            padding-left: 20px;
        }
    }
    &__MemoBox {
        &__list {
            height: 100%;
            overflow-y: auto;
            margin-bottom: 38px;
        }
        &__input  {
            width: 100%;
            position: absolute;
            bottom: 0;
        }
        &__contents {
            margin: 0 12.4px;
            border-bottom: 1px solid;
            &:last-child {
                border: none;
            }
            .time {
                display: flex;
                flex-direction: row-reverse;
            }
        }
    }
}
