.project_container {
  padding: 20px;
  background-color: #e8f7ff;
  border-radius: 10px;
  margin-bottom: 30px;
}

.project_item_container {
  background-color: #fff0c7;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
  input {
    margin-left: 10px;
  }
}

.projectTitle {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
}


.title_button_container {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  Button {
    margin-right: 10px;
  }
  .title_container {
    width: 50%;
  }
}
