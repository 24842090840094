.department_title_container {
  display: flex;
  min-width: 300px;
  margin-bottom: 15px;
}

.title_container {
  display: flex;
  width: 50%;
}

.button_container {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}

