.CustomTable {
  box-shadow: none !important;

  &__wrapper {
    overflow-x: auto;
  }

  &__contents {
    border: 1px solid #cccccc;
  }
  
  tbody {
    tr {
      td {
        word-break: keep-all;
        padding: 0px !important;
        font-size: 10px !important;
        border-right: 1px solid #cccccc;
        color: #797979;
        &:last-child {
          border-right: 0;
        }
      }
    }
  }
}
