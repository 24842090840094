.CustomModal {
    display: flex;
    justify-content: space-around;
    max-width: 80% !important;

    &__body {
        // max-height: 500px;
        // overflow-y: auto;
        display: flex;
        * {
            font-size: 14px;
        }
        &__confirm {
            background-color: var(--color-freemed-red) !important;
            border-color: var(--color-freemed-red) !important;
        }
        &__cencle {
            &:hover {
                background-color: var(--color-freemed-red) !important;
                border-color: var(--color-freemed-red) !important;
            }
        }
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #cccccc;

      button {
        margin: 10px 10px 10px 0;
      }
    }
}