.Header {
  background-color: white;

  tr {
    th {
      word-break: keep-all;
      padding: 0px !important;
      font-size: 12px !important;
      font-weight: 700 !important;
      text-align: center !important;
      color: #000;
      background-color: var(--color-gray-200);
      border-right: 1px solid #cccccc;
      &:last-child {
        border-right: 0;
      }
    }
  }
}
