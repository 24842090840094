.Navigation {
  margin: 10px 0px 20px 0px;
  display: flex;
  justify-content: flex-end;
  
  button {
    font-size: 12px;
  }
  .export-excel {
    background-color: #ED3827;
  }
  &__divide {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
}

.tt {

}