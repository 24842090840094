.InfoDetail {
  width: 100%;
  margin-right: 5px;
  max-height: 500px;
  overflow: auto;
}

.DetailBody .MuiCardContent-root-294{
  padding-bottom: 10px !important;
}

.MuiCardContent-root-238 {
  padding-bottom: 10px !important;
}

.Icon {
  margin-right: 0.3em;
  margin-top: 0.1em;
  font-size: 1.2em !important;
}

.InterviewCell {
  display: inline-block;
  width: 150px;
  height: 50px;
  border: 1px solid gray;
  margin: 5px 10px;
  border-radius: 3px;
  text-align: center;
  line-height: 50px;
  padding: 0px !important;
}

.InterviewCell.Selected {
  background-color: var(--color-freemed-red) !important;
  border-color: var(--color-freemed-red) !important;
  color: whitesmoke;
  font-weight: 600;
}

.Title {
  font-size: 20px;
  font-weight: 800;
}

.Content { 
  &__wrapper {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .SubTitle {
    font-size:17px;
    font-weight: 700;

    span {
      margin: 5px;
      font-size: 17px; 
    }
  }
  
  .Head-3 {
    font-size: 16px;
    margin: 5px;
    font-weight: 700;
  }
  
  .SubContent {
    margin: 5px;
    color: #797979;
  }
}