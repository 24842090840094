.chart-wrapper {
  display: flex;
  flex-direction: column;
  height:100vh;
  
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 700;
    text-align: left;
  }

  .chart-container {
    display: flex;
    .chart-sub-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex: auto;
      flex-wrap: wrap;

      .item {
        border: 1px solid #cccccc;
        margin-top: 10px;
        padding: 10px;
        display: block;
        width: 50%;
        &:nth-child(2n) {
          border-left: 0;
        }
      }
    }
  }
}
.applier-stat-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  table {
    width: 100%; 
    border-radius: 5px;
    border-collapse: collapse;
    text-align: center;
  }
  th, td {
    border: 1px solid #cccccc;
    padding: 10px;
  }
  th{
    background-color: var(--color-gray-200);
  }
  .total-count-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    span {
      font-weight: bold;
      margin-left: 30px;
      font-size: 18px;
    }
  }
}
