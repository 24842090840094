.start_end_container {
  display: flex;
  max-width: 700px;
  min-width: 300px;
  margin-bottom: 20px;
}

.date_container {
  margin-right: 20px;
  width: 50%;

  input {
    border: 2px solid #eb4034;
  }
}
