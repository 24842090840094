.Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  a:hover {
    text-decoration: none;
  }
  span {
    color: var(--color-black);
    font-weight: bold;
    font-size: 14px;
  }
  .ItemBox{
    display: flex;
    column-gap: 32px;
  }

  .ItemContainer {
    margin: 5px 0;
    &:hover {
      span, svg {
        color: var(--color-freemed-red);
      }
    }
    .ItemWrapper {
      display: block;
      margin: 0 auto;
    }
  }
  .Sidebar {
    display: flex;
    flex-flow: wrap column;
    margin: 0 auto;
    &__icon {
      margin-right: 5px !important;
      svg {
        color: var(--color-black);
      }
    }
    &__menu {
      padding: 0 8px;
    }
    &__item {
      padding: 0;
      word-break: keep-all;
    }
  }
}

.main-logo {
  display: flex;
  align-items: center;
  column-gap: 8px;;
  padding: 0 25px;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-black);
  font-family: 'NanumSquareNeoVariable', sans-serif;
  &:hover {
    color: var(--color-black);
  }
}

.main-img-footer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: 10px;

  .slogan {
    width: 100%;
    height: 100%;
  }
}

.LoginContainer {
  display: flex;
  width: 100%;
  padding: 10px;
  color: var(--color-black);
  column-gap: 8px;
  .LoginWrapper {
    display: flex;
    align-items: center;
    padding: 0 10px;
    .LoginInfoWrapper {
      width: 100%;
      padding-left: 10px;
      p {
        font-size: 12px;
        margin-bottom: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
    }
  }
}
