.Table {
  box-shadow: none !important;
  margin: 20px;
  min-width: 1200px;
  width: 100%;
  max-height: 100vh;
  overflow-x: hidden;

  &__titlebar {
    font-size: 20px;
    font-weight: 700;
  }

  &__pagination {
    bottom: 0;
  }

  &__header {
    display: flex;
  }

  &__logout {
    position: absolute;
    font-size: 10px !important;
    right: 20px;
    top: 20px;
    font-weight: 700;
  }
}


