:root {
  --color-white: #FFFFFF;
  --color-gray-100: #F6F7F8;
  --color-gray-200: #F1F3F5;
  --color-gray-700: #BBBBBB;
  --color-gray-800: #999999;
  --color-gray-900: #444444;
  --color-black: #151515;
  --color-freemed-red: #ED3827;
  --color-freemed-red-light: #FDEBE9;
}

body {
  font-family: 'Pretendard', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--color-white) !important;
}
