.recruitmetas_container {
  padding: 20px;
  background-color: #ffd9d4;
  border-radius: 10px;
  margin-bottom: 30px;
}
.recruitmeta_container {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  span {
    margin-right: 20px;
  }
  Button {
    margin-left: 20px;
  }
}
