.QuestionRegisContainer {
  &__addBtn {
    font-size: 14px;
    padding: 6px 12px !important;
  }
  &__addBox {
    position: relative;
  }
  &__addQuestion {
    position: absolute !important;
    top: 0;
    right: 8px;
    box-shadow: none !important;
    z-index: 2;
  }
}